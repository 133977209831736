import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular/standalone';

@Injectable({providedIn: 'root'})
export class ToastService {
  constructor(private readonly toastController: ToastController) {}

  async successLoggedIn(): Promise<void> {
    return this.createToast('✅ Connecté');
  }

  async successDeletedToast(): Promise<void> {
    return this.createToast('♻️ Compte supprimé');
  }

  async createToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'middle',
      translucent: true,
    });
    await toast.present();
  }
}
