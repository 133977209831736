import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {NavController} from '@ionic/angular/standalone';
import {BehaviorSubject, catchError, EMPTY, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FalucheEnvironment} from '../../faluche/src/app/model/faluche.environment';
import {PaillardesEnvironment} from '../../paillardes/src/app/model/paillardes-environment';
import {User} from '../model/user';
import {StorageService} from './storage.service';
import {ToastService} from './toast.service';

interface AuthResponse {
  jwt: string;
  user: User;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private readonly authUrl = `${this.environment.apiRestEndpoint}/auth/local`;
  private readonly loggedInStatus = new BehaviorSubject<boolean>(this.getLoggedIn());
  private readonly user = new BehaviorSubject<User>(this.getPersistedUser());
  loggedInStatus$ = this.loggedInStatus.asObservable();
  user$ = this.user.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly navController: NavController,
    private readonly storageService: StorageService,
    private readonly toastService: ToastService,
    @Inject('WINDOW') private readonly window: Window,
    @Inject('ENVIRONMENT') private readonly environment: FalucheEnvironment | PaillardesEnvironment,
  ) {}

  login(identifier: string, password: string): Observable<AuthResponse> {
    return this.httpClient
      .post<AuthResponse>(this.authUrl, {identifier, password})
      .pipe(tap((user: AuthResponse) => this.persistUserFromAuthResponse(user)));
  }

  register(username: string, email: string, password: string) {
    return this.httpClient
      .post<AuthResponse>(`${this.authUrl}/register`, {
        username,
        email,
        password,
      })
      .pipe(tap((user: AuthResponse) => this.persistUserFromAuthResponse(user)));
  }

  logout(): void {
    this.navController.navigateRoot('/').finally(() => {
      this.loggedInStatus.next(false);
      this.resetUser();
    });
  }

  deleteAccount(): void {
    this.httpClient
      .delete(`${this.environment.apiRestEndpoint}/user/destroyMe`, {
        headers: this.getAuthHeader(),
      })
      .pipe(
        tap(async () => this.navController.navigateRoot('/')),
        tap(() => this.loggedInStatus.next(false)),
        tap(() => this.resetUser()),
        tap(async () => this.toastService.successDeletedToast()),
      )
      .subscribe();
  }

  getAuthHeader(): {Authorization: string} {
    return {Authorization: `Bearer ${this.getPersistedToken()}`};
  }

  forgotPassword(email: string): Observable<void> {
    return this.httpClient.post(`${this.environment.apiRestEndpoint}/auth/forgot-password`, {email}).pipe(
      catchError((error) => {
        console.log('An error occurred:', error.response);
        return EMPTY;
      }),
      map(() => void 0),
    );
  }

  resetPassword(password: string, passwordConfirmation: string, code: string): void {
    this.httpClient
      .post<AuthResponse>(`${this.environment.apiRestEndpoint}/auth/reset-password`, {
        code,
        password,
        passwordConfirmation,
      })
      .pipe(
        tap((user: AuthResponse) => this.persistUserFromAuthResponse(user)),
        tap(async () => this.navController.navigateRoot('/')),
        tap(async () => this.toastService.successLoggedIn()),
        catchError((error) => {
          console.log('An error occurred:', error.response);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  facebookLogin(): void {
    this.window.location.href = `${this.environment.apiRestEndpoint}/connect/facebook`;
  }

  googleLogin(): void {
    this.window.location.href = `${this.environment.apiRestEndpoint}/connect/google`;
  }

  private persistUserFromAuthResponse({jwt, user: {id, username, createdAt, email}}: AuthResponse): void {
    this.storageService.setItem('userId', id);
    this.storageService.setItem('userEmail', email);
    this.storageService.setItem('username', username);
    this.storageService.setItem('createdAt', createdAt);
    this.storageService.setItem('loggedIn', 'true');
    this.storageService.setItem('marvin', jwt);
    this.loggedInStatus.next(this.getLoggedIn());
    this.user.next(this.getPersistedUser());
  }

  private resetUser(): void {
    ['userId', 'userEmail', 'username', 'loggedIn', 'token'].forEach((item) => this.storageService.removeItem(item));
    this.user.next(null);
  }

  private getPersistedUser(): User {
    const id = this.storageService.getItem('userId') || null;
    const username = this.storageService.getItem('username') || null;
    const email = this.storageService.getItem('userEmail') || null;
    if (id && username && email) {
      return {
        id,
        username,
        email,
        photoURL: null,
        createdAt: this.storageService.getItem('createdAt') || null,
      };
    }
    return null;
  }

  private getLoggedIn(): boolean {
    return this.storageService.getItem('loggedIn') === 'true';
  }

  private getPersistedToken(): string {
    return this.storageService.getItem('marvin') || '';
  }

  checkProviderAuth(route: ActivatedRouteSnapshot): Observable<boolean> {
    const accessToken = route.queryParams['access_token'];
    const provider = route.paramMap.get('provider');
    if (accessToken && provider) {
      return this.httpClient
        .get<AuthResponse>(`${this.environment.apiRestEndpoint}/auth/${provider}/callback?access_token=${accessToken}`)
        .pipe(
          tap(async () => this.toastService.successLoggedIn()),
          tap((user: AuthResponse) => this.persistUserFromAuthResponse(user)),
          tap(async () => this.navController.navigateRoot('/')),
          map(() => true),
          catchError((error) => {
            console.log('An error occurred:', error.response);
            return EMPTY;
          }),
        );
    }
    return of(true);
  }
}
